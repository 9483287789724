<template>
	<div>
		<div class="wraps-iim">
			<div class="titleab" style="font-size:18px;font-weight:700;    color: #3892eb;"> {{ xq.title }}</div>
			<div style="margin-top:20px; text-align: right;">{{xq.created_at.substring(0, 10) }}</div>

			<div class="wrap-all-tipbox">

				<div class="left-info-title">政策详情</div>

				<div class="wrap-tiptit-box">
					<p v-html="xq.text"></p>
				</div>
				<div v-show="xq.addfiles">附件:
					<a :href="'http://hout.kehui.cloud'+xq.addfiles" target="_blank" rel="noopener">{{xq.title}}</a>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import {
		formatDate
	} from "../../../common/js/times"; //时间
	import {
		PolicysXq
	} from "../../../common/js/api";
	export default {
		filters: {
			formatDate(time) {
				time = time * 1000;
				let date = new Date(time);
				return formatDate(date, "yyyy年MM月dd日");
			},
		},
		props: {
			item: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		data() {
			return {
				id: "",
				xq: {},
			};
		},
		watch: {
			item: {
				handler(val, oldVal) {
					if (this.item.dialogVisible == true && this.id != val.id) {
						this.id = val.id;
						this.PolicysXq(val.id);
					}
				},
				deep: true,
			},
		},
		created() {
			this.id = this.item.id;
			this.PolicysXq(this.id);
		},
		methods: {
			//   政策详情
			PolicysXq(id) {
				PolicysXq({
						id: id,
					})
					.then((res) => {
						this.xq = res.data;
						this.xq.level = this.xq.leveltype.name;
					})
					.catch(() => {});
			},

		},
	};
</script>
<style lang="less" scoped>
	.left-info-title {
		font-size: 18px;
		font-weight: 700;
		margin: 20px 0 10px;
		color: #010101;
	}


	.wrap-tiptit-box {
		line-height: 1.5;
		color: #010101;
		padding-bottom: 50px;
	}
	
	@media screen and (max-width: 1200px) {
		.left-info-title {
			font-size: 1.8rem;
		}
		p{
			font-size: 1.4rem !important
		}
		.titleab{
			font-size: 2rem !important;
			line-height: 2.5rem;
		}
	}
</style>