<template>
  <div class="content-box">
    <div class="content">
      <!-- 搜索 -->
      <div class="search-action">
        <div class="inputs_filter">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
            @clear="getList"
          >
          </el-input>
          <div class="search-btn" @click="getList">搜索</div>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="filters-list">
        <ul class="filters">
          <li
            class="fitler-item"
            v-for="(goods, goodsIndex) in goodsList"
            :key="goodsIndex"
          >
            <span class="nameFrame">{{ goods.title }}</span>
            <div class="children-cate">
              <ul class="cates">
                <li
                  :class="{
                    active: typeIndex === goods.index,
                  }"
                  v-for="(type, typeIndex) in goods.typeList"
                  :key="typeIndex"
                  @click="handleclick(typeIndex, goods, type, goodsIndex)"
                >
                  {{ type.name }}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="title" label="内容列表">
            <template slot-scope="scope">
              <p v-if="scope.$index == 0"  @click="handleEdit(scope.$index, scope.row)">
                <span class="tablered">置顶</span> {{ scope.row.title }}
              </p>
              <p v-else  @click="handleEdit(scope.$index, scope.row)">{{ scope.row.title }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="views" label="浏览量"  width="150">
          </el-table-column>
          <el-table-column label="上传日期" width="150">
            <template slot-scope="scope">
              {{ scope.row.created_at.substring(0, 10) }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <pagination
            style="padding: 0"
            class="pages"
            v-show="total > 0"
            :total="total"
            layout="total,  prev, pager, next, jumper"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getList"
          />
        </div>
      </div>
    </div>

       
    <!-- 弹出框 -->
    <el-dialog
      class="dialog"
       :visible.sync="dia.dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <Xq :item="dia"></Xq>
    </el-dialog>
  </div>
</template>

<script>
import { Library, Ares, ZCFL } from "../../../common/js/api";
import Pagination from "@/common/Pagination";
import Xq from "./xq52";

export default {
  components: { Pagination , Xq },
  data() {
    return {
      FilterListId: {},
      goodsList: [
        {
          title: "地区分类：",
          typeList: [],
        },
        {
          title: "项目分类：",
          typeList: [],
        },
      ],
      tableData: [],
      total: 0, //总条目数
      listQuery: {
        limit: 10,
        page: 1,
      },
      search: "",
       dia: {
        dialogVisible: false,
        id: "",
      },
    };
  },
  created() {
      if(this.$route.query.id!=undefined){
          this.dia.dialogVisible = true;
          this.dia.id = this.$route.query.id
      }
    this.goodsList.forEach((item) => this.$set(item, "index", 0));
    this.getList();
    this.getAres();
    this.getPolicyType();
  },
  methods: {
    // 列表
    getList() {
      this.getLibrary(this.FilterListId);
    },

    // 筛选
    handleclick(typeIndex, goods, type, goodsIndex) {
      goods.index = typeIndex;
      if (goodsIndex == 0) {
        this.$set(this.FilterListId, goodsIndex, type.name);
      } else {
        this.$set(this.FilterListId, goodsIndex, type.id);
      }

      this.getLibrary(this.FilterListId);
    },
    getLibrary(list) {
      if (list[0] == "全部") {
        list[0] = "";
      }
      Library({
        keywords: this.search,
        province: list[0],
        type: list[1],
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
    // 地区
    getAres() {
      Ares()
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]["name"] = res.data[i].title;
          }
          var b = { name: "全部" };
          res.data.unshift(b);
          this.goodsList[0].typeList = res.data;
        })
        .catch(() => {});
    },
    getPolicyType() {
      ZCFL()
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.goodsList[1].typeList = res.data;
        })
        .catch(() => {});
    },
      // 详情
    handleEdit(index, row) {
      this.dia.dialogVisible = true;
      this.dia.id = row.id;
     },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
 
  },
};
</script>


 <style scoped src="@/assets/css/page.css"></style>
<style lang="less" scoped>
.el-table__row:hover p {
  color: #3892eb;
  cursor: pointer;
}

/deep/.el-dialog__body {
    padding: 10px 60px 20px;
    height: 600px;
    overflow-y: scroll;
      
}
 /deep/.el-dialog__body::-webkit-scrollbar {
    width: 15px;
  }
   // -修改 滑块
  /deep/.el-dialog__body::-webkit-scrollbar-thumb {
    background-color: #e7e7e7;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

.content {
  .search-action {
    margin: 20px 20%;
  }
  /deep/.el-input__inner {
    border: 0;
  }
  .filters-list {
    // width: 1373px;
    margin: 0 auto;
  }
  .table {
    // width: 1373px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #ebeef5;
    p {
      padding: 5px 0;
    }
    .tablered {
      font-size: 12px;
      background: #ff2800;
      padding: 3px 15px;
      border-radius: 20px;
      color: #fff;
    }
    .pagination {
      margin: 10px;
      background-color: #fff;
      border: 0;
    }
  }
}
</style>